<!--
  需求详情：其他资源推荐的是需求（根据此需求Id，推荐其他需求）
-->
<template>
  <div class="resource_detail" v-loading="loading">
    <div class="detail_left">
      <!-- 需求详情 -->
      <div class="require_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>需求详情</p>
        </div>
        <div class="module_box_main">
          <div class="main_header">
            <div class="header_left">
              <h1>{{ detail.title }}</h1>
              <div class="tag">{{ detail.serviceTypeName }}</div>
            </div>
            <div class="header_right">
              <div class="price_box">
                预算金额：{{ detail.budget }}
              </div>
              <div class="date_box">
                <span>发布时间 </span>
                <span>{{detail.createdAt | dateFormat}}</span>
              </div>
            </div>
          </div>
          <div class="main_content">
            <p class="description">{{ detail.description }}</p>
            <div class="text_list">
              <el-row type="flex">
                <div class="text_item">
                  <div class="item_label">
                    技术领域：
                  </div>
                  <div class="item_value">
                    {{ detail.technicalName ? detail.technicalName.toString() : '' }}
                  </div>
                </div>
                <div class="text_item">
                  <div class="item_label">
                    时限要求：
                  </div>
                  <div class="item_value">
                    {{ detail.startAt }} - {{ detail.endAt }}
                  </div>
                </div>
              </el-row>
            </div>
          </div>
          <!-- 不能接受自己的服务 && 状态为已发布 -->
          <div
            class="main_footer"
            v-if="canPlaceOrder">
            <el-button
              type="primary"
              @click="placeOrder">
              提交报价
              <i class="iconfont icongouwu tw-ml-5px"></i>
            </el-button>
          </div>
        </div>
      </div>
      <!-- 机构介绍 -->
      <div
        @click="onOrgDetail"
        class="org_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>机构介绍</p>
        </div>
        <div class="module_box_main">
          <div class="main_left">
            <!-- 通过了认证才有小盾牌 -->
            <h1>{{ orgInfo.orgName }}</h1>
            <div class="count_box">
              <div class="count_item">
                <h1 class="tw-text-primary">
                  {{ orgInfo.organization ? orgInfo.organization.avgScore : '' }}
                </h1>
                <p>信用评价</p>
              </div>
            </div>
          </div>
          <div class="main_right">
            <el-row type="flex">
              <div class="text_item">
                <div class="item_label">
                  联系人：
                </div>
                <div class="item_value">
                  {{ orgInfo.username }}
                </div>
              </div>
              <div class="text_item">
                <div class="item_label">
                  联系方式：
                </div>
                <div class="item_value">
                  {{ orgInfo.mobile }}
                </div>
              </div>
            </el-row>
            <el-row type="flex">
              <div class="text_item">
                <div class="item_label">
                  行业领域：
                </div>
                <div class="item_value">
                  {{ orgInfo.organization ? getTechnicalNames(orgInfo.organization.industryExpertise) : '' }}
                </div>
              </div>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <!--
      如果此需求是组合需求
        + 此处推荐的是组合需求中的需求
      如果此处需求非组合需求
        + 则推荐其他
     -->
    <div class="detail_right">
      <div class="recommend_list module_box tw-mb-20px">
        <div class="module_box_header">
          <p>其他相关资源推荐</p>
        </div>
        <div class="module_box_main">
          <router-link
            tag="a"
            :to="`/require-detail/${item.id}?from=recommend`"
            target="_blank"
            class="recommend_item"
            v-for="item in requireRecommendList"
            :key="item.id">
            <div class="item_header">
              <h1>{{ item.title }}</h1>
              <p>
                <template v-if="item.budget === '面议'">
                  面议
                </template>
                <template v-else>
                  ￥{{ item.budget }}元
                </template>
              </p>
            </div>
            <div class="item_footer">
              <el-image
                v-if="item.avatarUrl"
                style="width: 24px; height: 24px"
                :src="item.avatarUrl"
                fit="cover">
              </el-image>
              <img v-else src="../../../assets/images/default_avatar.png" width="24" height="24" style="object-fit: cover;" />
              <p>{{ item.username }}</p>
            </div>
          </router-link>
        </div>
        <!-- <div class="module_box_footer">
          <div class="refresh_btn">
            换一批 <i class="iconfont iconshuaxin tw-ml-10px"></i>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 添加报价 -->
    <add-price
      :visible.sync="dialogVisible"
      :requireId="requireId">
    </add-price>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
import addPrice from './add-price.vue'
export default {
  components: {
    addPrice
  },
  data () {
    return {
      // 页面loading
      loading: false,
      // 报价弹窗
      dialogVisible: false,
      // 详情
      detail: {},
      // 机构信息
      orgInfo: {},
      // 服务推荐列表
      requireRecommendList: []
    }
  },
  computed: {
    // 服务id
    requireId () {
      return this.$route.params.id
    },
    // 来源 [recommend]
    from () {
      return this.$route.query.from || ''
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    getTechnicalNames () {
      return (technical) => {
        const ids = technical ? technical.split(',') : []
        let result = ''

        this.technicalList.forEach(item => {
          if (ids.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    /**
     * 可以被下单
     *  + 需求状态===已发布
     *  + 角色为 服务提供商
     */
    canPlaceOrder () {
      return this.detail.logicStatus === globalVar.REQUIRE_STATUS_PUBLISHED && (this.isServiceOrg || this.isServiceAggregator)
    }
  },
  created () {
    // 获取需求详情
    this.getRequireDetail()
  },
  methods: {
    // 获取需求详情
    getRequireDetail () {
      this.loading = true
      api.getRequireDetail(this.requireId, {
        from: this.from
      }).then(res => {
        if (res.data.code === 0) {
          console.log('需求详情', res.data.data)
          this.detail = res.data.data
          this.orgInfo = res.data.data.orgInfo
          // 根据此需求id 获取
          this.getRecommendRequireList(this.detail.id)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取需求推荐列表
    getRecommendRequireList (id) {
      api.getRecommendRequireList({
        reqId: id
      }).then(res => {
        if (res.data.code === 0) {
          console.log('需求推荐列表', res.data.data)
          this.requireRecommendList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },
    // 下单
    placeOrder () {
      this.dialogVisible = true
    },
    // 去机构详情页面
    onOrgDetail () {
      // 如果不是服务机构的话 则无机构的详情
      if (!this.orgInfo.role) return
      window.open(`/org-detail/${this.orgInfo.organizationId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .resource_detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .detail_left {
      flex: 1;
      margin-right: 20px;
      .require_info {
        .module_box_main {
          padding-bottom: 30px;
          .main_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 67px;
            border-bottom: 1px solid #DCDFE6;
            .header_left {
              display: flex;
              height: 100%;
              align-items: center;
              & > h1 {
                font-size: 26px;
                font-weight: bold;
                line-height: 37px;
                color: #000000;
                margin-right: 30px;
              }
              .tag {
                height: 20px;
                padding: 0 5px;
                background: rgba(52, 115, 230, 0);
                border: 1px solid #DCDFE6;
                border-radius: 2px;
                font-size: 12px;
                font-weight: bold;
                line-height: 20px;
                color: #3473E6;
                text-align: center;
              }
            }
            .header_right {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 500px;
              height: 100%;
              padding: 0 20px;
              background-image: url('../../../assets/images/detail/price_bg.png');
              background-repeat: no-repeat;
              .price_box {
                font-size: 16px;
                font-weight: bold;
                line-height: 33px;
                color: #FF4343;
                & > span {
                  font-size: 24px;
                }
                .date_box {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  color: #303133;
                  & > span {
                    &:first-child {
                      margin-right: 20px;
                    }
                  }
                }
              }
            }
          }
          .main_content {
            padding: 24px 0 40px 0;
            & > .description {
              font-size: 14px;
              font-weight: 500;
              line-height: 30px;
              color: #303133;
              margin-bottom: 25px;
            }
            .text_list {
              .text_item {
                display: flex;
                // max-width: 320px;
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 10px;
                .item_label {
                  // flex: 0 0 90px;
                  width: 100px;
                  color: #909399;
                }
                .item_value {
                  // flex: 0 0 230px;
                  width: 220px;
                  color: #303133;
                }
              }
            }
          }
          .main_footer {
            display: flex;
            align-items: center;
            & > a {
              font-size: 12px;
              font-weight: 500;
              line-height: 17px;
              color: #C0C4CC;
              margin-left: 20px;
            }
          }
        }
      }
      .org_info {
        background: #fff url('../../../assets/images/detail/about_the_company.png') right bottom no-repeat;
        .module_box_main {
          display: flex;
          padding-bottom: 30px;
          .main_left {
            margin-right: 60px;
            & > h1 {
              font-size: 20px;
              font-weight: bold;
              line-height: 60px;
              color: #000000;
              margin-right: 10px;
              & > i {
                color: #3473E6;
              }
            }
            .count_box {
              position: relative;
              display: flex;
              width: 130px;
              height: 70px;
              background-color: #F7F8F9;
              border-radius: 4px;
              .count_item {
                flex: 1;
                text-align: center;
                padding: 10px 0;
                & > h1 {
                  font-size: 20px;
                  font-weight: bold;
                  line-height: 30px;
                }
                & > p {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 20px;
                  color: #303133;
                }
              }
            }
          }
          .main_right {
            .text_item {
              display: flex;
              // max-width: 320px;
              font-size: 14px;
              line-height: 30px;
              margin-bottom: 10px;
              .item_label {
                // flex: 0 0 90px;
                width: 100px;
                color: #909399;
              }
              .item_value {
                // flex: 0 0 230px;
                width: 280px;
                color: #303133;
              }
            }
          }
        }
      }
    }
    .detail_right {
      flex: 0 0 450px;
      .recommend_list {
        .module_box_main {
          padding-bottom: 20px;
          .recommend_item {
            padding-bottom: 10px;
            border-bottom: 1px dashed #DCDFE6;
            .item_header {
              display: flex;
              height: 50px;
              align-items: center;
              justify-content: space-between;
              & > h1 {
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                color: #606266;
                padding-right: 20px;
              }
              & > p {
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                color: #909399;
              }
            }
            .item_footer {
              display: flex;
              align-items: center;
              height: 30px;
              & > p {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                color: #909399;
                margin-left: 10px;
              }
            }
            &+.recommend_item {
              margin-top: 10px;
            }
          }
        }
        .module_box_footer {
          padding-bottom: 20px;
          .refresh_btn {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #3473E6;
            cursor: pointer;
          }
        }
      }
    }

    .module_box {
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 0 20px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      overflow: hidden;
      .module_box_header {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 10px;
        &::before {
          content: "";
          width: 3px;
          height: 20px;
          background: #3473E6;
          border-radius: 1px;
          margin-right: 10px;
        }
        & > p {
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          color: #31394C;
        }
      }
      .module_box_main {
        padding: 0 10px;
      }
      .module_box_footer {
        padding: 0 10px;
      }
    }
  }
</style>
